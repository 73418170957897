<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Create new banner</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<router-link
				to="/games&campaigns/banner"
				class="btn btn-light mb-4 btn-sm"
				>
				Go back
			</router-link>

			<div>
				<b-breadcrumb :items="items"></b-breadcrumb>
			</div>
			<div class="row">
				<div class="col-12 col-xl-6">
					<b-card>
						<form @submit.prevent="handleBanner">
							<div class="form-group">
								<label style="color: #444854" class="form-label">Title</label>
								<input
									placeholder="Enter title"
									v-model="form.title"
									required
									type="text"
									class="form-control"
									/>
							</div>

							<b-card>
								<div
									class="d-flex justify-content-center align-items-center flex-column"
									>
									<img
										@click="handleUpload"
										src="@/assets/img/uploadIcon.svg"
										alt=""
										class="mb-4"
										style="cursor: pointer"
										/>

									<p class="pb-0 mb-0 text-muted" style="font-size: 13px">
										<span
											class="uploadText"
											@click="handleUpload"
											style="color: #4848ed; font-weight: bold"
											>
											Click to upload</span
											>
										or drag and drop
									</p>

									<p class="pt-0 mt-0 text-muted" style="font-size: 13px">
										SVG, PNG, JPG or GIF (max. 2021 x 724px)
									</p>
									<input
										type="file"
										accept="image/*"
										ref="uploadRef"
										style="display: none"
										@change="onFileSelected"
										/>
								</div>
							</b-card>
							<b-alert
								:show="show_size_warning_alert"
								fade
								dismissible
								variant="danger"
								>
								<small>
									Image does not meet the size requirements, upload a new
									image</small
									>
							</b-alert>

							<div class="d-flex align-items-center pt-0 mt-0 mb-5">
								<img src="@/assets/img/info_circle.svg" alt="uplodIcon" />
								<small class="text-muted text-secondary font-weight-light pl-3"
									>Images to be uploaded must have a dimension of 2021 x
									724px</small
									>
							</div>

							<div class="form-group">
								<label style="color: #444854" class="form-label"
									>Banner CTA</label
									>
								<v-select
									class="form-group"
									:options="CTAOptions"
									v-model="selected"
									>
								</v-select>
							</div>

							<div
								v-if="selected === 'Embed link'"
								class="form-group ctaUrlParent"
								>
								<label style="color: #444854" for="CTAUrl" class="form-label"
									>Link</label
									>
								<input
									id="CTAUrl"
									name="CTAUrl"
									placeholder="Enter link"
									required
									type="url"
									class="form-control"
									v-model="form.embeded_link"
									/>
								<img
									src="@/assets/img/linkIcon.svg"
									class="ctaUrlChild"
									alt=""
									/>
							</div>

							<div
								v-if="selected === 'Link to carousel'"
								class="form-group ctaUrlParent"
								>
								<label style="color: #444854" for="CTAUrl" class="form-label"
									>Select Carousel</label
									>
								<v-select
									v-model="form.selectedCarousel"
									label="name"
									class="form-group"
									:options="carouselsList"
									:reduce="(carousel) => carousel.id"
									>
								</v-select>
							</div>

							<div v-if="selected === 'Link to route'" class="form-group">
								<label style="color: #444854" class="form-label"
									>Select route</label
									>
								<v-select
									v-model="form.route"
									class="form-group"
									placeholder="Search"
									:reduce="(route) => route.id"
									:options="routes"
									label="route_code"
									required
									>
									<template v-slot:option="route">
										<span>{{
											`${
												route.route_code ? route.route_code + ' - ' : ''
											} From ${route.pickup} to ${route.destination}`
										}}</span>
									</template>
									<template v-slot:selected-option="route">
										<span>{{
											`${
												route.route_code ? route.route_code + ' - ' : ''
											} From ${route.pickup} to ${route.destination}`
										}}</span>
									</template>
								</v-select>
							</div>

							<div class="d-flex justify-content-end align-items-end">
								<button
									:disabled="!isFormValid || processing"
									type="submit"
									:class="
										!isFormValid || processing ? 'btn-secondary' : 'btn-primary'
									"
									class="btn mt-4"
									>
									{{ processing ? 'Creating banner...' : `Add Banner` }}
								</button>
							</div>
						</form>
					</b-card>
				</div>

				<div class="col-12 col-xl-6">
					<b-card v-if="uploadingBannerToS3 === true">
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2">
								<strong class="text-muted">Uploading banner...</strong>
							</p>
						</div>
					</b-card>

					<b-card v-if="form.previewUrl && uploadingBannerToS3 === false">
						<div
							class="card-header d-flex justify-content-between align-items-center"
							>
							<h4 class="card-header-title">Banner Image Preview</h4>
							<button
								@click="removeImage"
								type="submit"
								class="btn text-danger border-danger"
								>
								Remove image
							</button>
						</div>

						<div id="preview" class="mt-0 pt-0">
							<img
								alt="imagePreview"
								class="imageDimensions"
								:src="form.previewUrl"
								/>
						</div>
					</b-card>

					<b-card v-if="!form.previewUrl && uploadingBannerToS3 === false">
						<div
							class="d-flex justify-content-center align-items-center flex-column"
							>
							<img src="@/assets/img/imagePreviewEmptyState.svg" alt="" />
							<h3>No image to preview</h3>
							<p class="pt-0 mt-0 text-muted">Start uploading images</p>
						</div>
					</b-card>
				</div>
			</div>
		</div>

		<b-modal
			no-close-on-esc
			no-close-on-backdrop
			id="bannerSuccessModal"
			hide-header-close
			hide-footer
			hide-header
			centered
			>
			<div
				class="d-flex justify-content-between align-items-center flex-column"
				>
				<img src="@/assets/img/successIcon.svg" alt="" />
				<h2 class="pt-4">New promotional banner added</h2>
				<p class="text-muted pb-0 mb-0" style="font-weight: lighter">
					You have successfully added a new banner,
				</p>
				<p class="text-muted" style="font-weight: lighter">
					Kindly enable it to be visible.
				</p>
			</div>

			<button
				@click="hideSuccessBanner"
				type="submit"
				class="btn btn-primary mt-4 w-100"
				>
				Dismiss
			</button>
		</b-modal>
	</div>
</template>

<script>
import routeResource from '@/api/route'
export default {
  name: 'ManageBaner',
  data () {
    return {
      processing: false,
      items: [
        {
          text: 'Promotional banner',
          to: { name: 'BannerConfig' },
          active: false,
          exact: true
        },
        {
          text: 'Create new banner',
          href: '#',
          active: true,
          exact: true
        }
      ],
      CTAOptions: [
        'Embed link',
        'Link to carousel',
        'Link to route',
        'Link to discovery page'
      ],
      routes: [],
      selected: '' || 'Embed link',
      height: null,
      width: null,
      show_size_warning_alert: false,
      fetchingCarousels: false,
      uploadingBannerToS3: false,
      carouselsList: [],
      form: {
        route: null,
        previewUrl: '',
        selectedCarousel: '',
        image_url: '',
        title: '',
        embeded_link: '',
        select_discovery: false
      }
    }
  },
  created () {
    this.fetchRoutes()
    this.loadCarousels()
  },
  methods: {
    removeImage () {
      this.form.previewUrl = ''
    },
    loadCarousels () {
      this.fetchingCarousels = true
      this.axios
        .get('/v1/dynamic-dashboard/carousels')
        .then((res) => {
          this.carouselsList = res?.data
        })
        .catch(() => {
          this.$toastr.e(
            'Something went wrong while fetching carrousels',
            'Error'
          )
        })
        .finally(() => {
          this.fetchingCarousels = false
        })
    },
    fetchRoutes () {
      // TODO: raise issue with backend engineer on field added except by specifying limit
      routeResource
        .listRoute({
          params: { status: 1, limit: 1000000 }
        })
        .then((res) => {
          this.routes = res
        })
        .catch(() => {})
    },

    handleUpload (e) {
      this.$refs.uploadRef.click()
    },
    handleBanner () {
      if (!this.isFormValid) {
        return
      }
      this.processing = true
      const payload = {}
      payload.location = null
      payload.title = this.form.title
      payload.image_url = this.form.image_url

      if (this.selected === 'Embed link') {
        payload.action_type = 'open_link'
        payload.action_value = String(this.form.embeded_link)
      }

      if (this.selected === 'Link to carousel') {
        payload.action_type = 'open_carousel'
        payload.action_value = String(this.form.selectedCarousel)
      }

      if (this.selected === 'Link to route') {
        payload.action_type = 'open_route'
        payload.action_value = String(this.form.route)
      }

      if (this.selected === 'Link to discovery page') {
        payload.action_type = 'open_route_discovery'
        // payload['action_value'] = String(this.form.route)
      }

      this.axios
        .post('/v1/dynamic-dashboard/banners', payload)
        .then(() => {
          (this.form.route = null),
          (this.form.previewUrl = ''),
          (this.form.image_url = ''),
          (this.form.title = ''),
          (this.form.embeded_link = ''),
          this.showSuccessBanner()
        })
        .catch(() => {
          this.$toastr.e('Something weent wrong', 'Error')
        })
        .finally(() => {
          this.processing = false
        })
    },
    showSuccessBanner () {
      this.$bvModal.show('bannerSuccessModal')
      this.$router.push({
        name: 'BannerConfig'
      })
    },
    hideSuccessBanner () {
      this.$bvModal.hide('bannerSuccessModal')
    },
    onFileSelected () {
      const input = this.$refs.uploadRef
      const files = input.files
      const image = new Image()

      image.onload = () => {
        if (image.height !== 724 && image.width !== 2021) {
          this.show_size_warning_alert = true
        } else {
          this.show_size_warning_alert = false
          this.uploadTos3andGetDocumentUrl(input.files[0]).then((res) => {
            this.form.image_url = res
            this.form.previewUrl = URL.createObjectURL(input.files[0])
          })
        }
      }

      if (files && files[0]) {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          image.src = e.target.result
          this.form.image_url = e.target.result
        }

        fileReader.readAsDataURL(files[0])
      }
    },
    async uploadTos3andGetDocumentUrl (file) {
      this.uploadingBannerToS3 = true
      try {
        const formData = new FormData()
        formData.append('file', file)
        const response = await this.$axios.post(
          '/v1/upload/identity/files',
          formData
        )
        if (response.data?.files?.length) {
          this.uploadingBannerToS3 = false
          return response.data.files[0].Location
        }
      } catch (error) {
        this.$toastr.e('Something weent wrong while uploading banner.', 'Error')
        ;(this.form.previewUrl = ''),
        (this.form.image_url = ''),
        (this.uploadingBannerToS3 = false)
      }
    }
  },
  computed: {
    isFormValid () {
      return !!(this.form.title &&
        this.form.image_url &&
        !this.uploadingBannerToS3 &&
        (this.form.embeded_link ||
          this.form.route ||
          this.form.selectedCarousel ||
          this.form.select_discovery))
    }
  },
  watch: {
    selected (val) {
      if (val === 'Link to discovery page') {
        this.form.select_discovery = true
      } else {
        this.form.select_discovery = false
      }
    }
  }
}
</script>

<style scoped>
.ctaUrlParent {
  position: relative;
}

.ctaUrlChild {
  position: absolute;
  top: 42px;
  right: 10px;
  color: gray;
  font-size: 20px;
}

.custom-file-upload {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
}

.uploadText {
  cursor: pointer;
}

.imageDimensions {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
